<template>
  <a-modal
    v-model="addModal"
    :dialog-style="{ top: '10px' }"
    width="1200px"
    :loading="submitLoading"
    title="批量剧评新增"
    @ok="submit()"
    @cancel="addModal=false"
  >
    <div style="display: flex; justify-content: flex-start;">
      <a-button type="primary" style="margin-right: 10px" @click="addPerson">
        <a-icon type="plus" />
        新增用户
      </a-button>
      <a-button type="danger" @click="delPerson">
        <a-icon type="delete" />
        删除当前用户
      </a-button>
    </div>
    <a-collapse accordion @change="changeList" style="margin-top: 10px;" v-if="personAllData.length > 0">
      <a-collapse-panel
        v-for="(item, index) in personAllData"
        :key="index"
      >
        <template v-slot:header>
          <span @click="$refs.userCardRef.open()">{{ item.userName }}</span>
          <a @click="$refs.userCardRef.open()" v-if="!item.userName">
            <a-icon type="user" />绑定用户
          </a>
        </template>
        <BatchDramaReviewsAddDetail
          :ref="`BatchDramaReviewsPerson${index}`"
          v-if="item.userName"
          :evaluationList="personAllData[index].evaluationList"
        />
      </a-collapse-panel>
    </a-collapse>
    <UserCardList
      :ref="`userCardRef`"
      @bindUser="bindUser"
    />
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import BatchDramaReviewsAddDetail from '@/views/community/article/modules/BatchDramaReviewsAddDetail.vue'
import UserCardList from '@/views/community/article/modules/UserCardList.vue'
import { listArticle } from '@/api/community/article'
import { batchAddEvaluationList } from '@/api/gm/gmUserLog'

export default {
  name: 'BatchDramaReviewsPerson',
  props: {
  },
  components: {
    BatchDramaReviewsAddDetail,
    UserCardList
  },
  data() {
    return {
      addModal: false,
      submitLoading: false,
      personAllData: [],
      pIndex: 0,
    }
  },
  filters: {},
  created() {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    openModal() {
      this.reset()
      this.addModal = true
    },
    addPerson () {
      this.personAllData.push({
        userId: undefined,
        userName: '',
        userRow: {},
        evaluationList: []
      })
      if (this.personAllData.length === 1) {
        this.pIndex = 0
      }
    },
    delPerson () {
      this.personAllData.splice(this.pIndex, 1)
    },
    changeList (index) {
      if (index) {
        this.pIndex = Number(index)
      }
    },
    /** 查询星河村-动态文章列表 */
    getList () {
      this.loading = true
      listArticle(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    // 全部提交
    submit() {
      let allValArray = []
      const that = this
      // 遍历所有表单
      this.personAllData.forEach((_, index) => {
        const formRefs = that.$refs['BatchDramaReviewsPerson' + index]
        if (formRefs) {
          allValArray.push(formRefs[0].checkAllVal())
        }
      });

      Promise.all(allValArray).then(() => {
        console.log(this.personAllData)
        batchAddEvaluationList(this.personAllData).then(() => {
          this.$message.success("已定时发布！")
          this.addModal = false
          this.personAllData = []
        }).catch(err => {
          this.$message.error(err)
        })
      }).catch(err => {
        this.$message.warning('请检查所有页面数据！')
      })
    },
    // 表单重置
    reset() {
      this.form = {}
    },
    bindUser (row) {
      this.personAllData[this.pIndex].userId = row.userId
      this.personAllData[this.pIndex].userName = row.nickName
    }
  }
}
</script>
<style lang="css" scoped>
>>> .ant-modal-body {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
